import React, { useState, useRef } from 'react'
import {
  ChatInputForm,
  ConversationDisplay,
  ChatDisclaimer,
  useConversation,
  useConversationAutoScroll,
} from 'components/lib'

import { nzTax } from './definitions'
import ChatHistory from 'components/ai/chatHistory'
import TaxAiChatSetting from 'components/ai/taxAiChatSetting'

export function LawCyborg() {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    checkedNS,
    setCheckedNS,
    namespaceCheckItems,
    mode,
    setMode,
    branch,
    swapBranch,
    loadConversation,
    conversationId,
    stopStreaming,
    loadConversations,
    historyCached,
  } = useConversation(nzTax)

  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>

  // LV LCEN-149 20231113 We allow for a total of 10 questions
  // in this chat which is 10 user messages plus the previous 9 responses (19 total).
  // Note, even though the max is 19, this does not block the final response so there
  // will still be 20 messages after the 10th question.
  const MAX_CHAT_LENGTH = 19
  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative">
      <TaxAiChatSetting
        checkedNS={checkedNS}
        setCheckedNS={setCheckedNS}
        mode={mode}
        namespaceCheckItems={namespaceCheckItems}
        setMode={setMode}
      />

      <ChatHistory
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f3f8fa'}
        onOpen={loadConversations}
        loading={!historyCached}
        enableRefresh={exchanges.length}
      />

      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%]  no-scrollbar" id="chat">
        {!exchanges.length && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={nzTax.disclaimers}
          />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
        />
      </div>

      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        {/* <RefreshButton enabled={exchanges.length} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          inputReadOnly={exchanges.length >= MAX_CHAT_LENGTH}
          placeholderText={
            exchanges.length >= MAX_CHAT_LENGTH
              ? 'A maximum of 10 questions can be asked in this chat. Please refresh to start a new conversation.'
              : 'Ask a question...'
          }
        />
      </div>
    </div>
  )
}
