/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

// When we have multiple plans available, uncomment all comments that have $ at the beginning
// and remove the code that it replaces (if any)

import React, { useContext, useEffect, useState } from 'react'
import {
  Animate,
  AuthContext,
  Row,
  Card,
  PaymentForm,
  usePlans,
  Link,
  useNavigate,
  Event,
  Checkbox,
  ViewContext,
} from 'components/lib'
import LCLSubscriberTermsConditionsAgreement from 'components/termsofservice/LCLSubscriberTermsConditionsAgreementStyles'
export function SignupPlan(props) {
  const context = useContext(AuthContext)
  const viewContext = useContext(ViewContext)
  const navigate = useNavigate()
  const plans = usePlans()
  const plan = window.location.hash.substring(1)

  // Use useState to manage termsAndConditions state
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)

  const handleTermsAndConditionsChange = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.modal.show(
        {
          text: <LCLSubscriberTermsConditionsAgreement />,
          className:
            'lg:h-3/4 h-4/5 overflow-y-auto max-w-5xl absolute left-1/2 top-1/2 text-left transform -translate-x-1/2 -translate-y-1/2 md:w-3/5 w-full duration-300 transition-all ease-in-out ',
          form: {},
          buttonText: 'Accept',
        },
        () => {
          setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
          viewContext.modal.hide()
        }
      )
    }
  }

  const isTermsAccepted = () => {
    if (!termsAndConditionsAccepted) {
      viewContext.notification.show('Please accept the Subscriber Terms & Conditions', 'error', true)
    }
    return termsAndConditionsAccepted
  }
  if (!plans.data) return false
  return (
    <Animate type="pop">
      <Row>
        <Card loading={false} restrictWidth center>
          <div class="flex flex-col justify-center items-center text-center mb-4">
            <span class="font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-cyan-500 to-blue-500 pb-10">
              Early adopter plan
            </span>
            <span class="text-5xl font-extrabold text-brand-500">$39</span>
            <span class="text-brand-500">(excluding GST)</span>
            <span class="text-gray-500 mt-1">per user per month</span>
          </div>
          <div class="flex mt-10 mb-4">
            <Checkbox
              name="termsAndConditions"
              option={
                <p>
                  I accept the{' '}
                  <button
                    onClick={handleTermsAndConditionsChange}
                    className="text-sky-500 hover:text-sky-700 focus:outline-none"
                  >
                    Subscriber Terms & Conditions
                  </button>
                </p>
              }
              checked={termsAndConditionsAccepted}
              callback={handleTermsAndConditionsChange}
            />
          </div>
          <PaymentForm
            inputs={{
              plan: {
                label: 'Plan',
                type: 'hidden',
                value: plans.data.list[0].value,
              },
              token: {
                label: 'Credit Card',
                type: 'creditcard',
                required: true,
              },
            }}
            onBeforeSubmit={isTermsAccepted}
            url="/api/account/plan"
            method="POST"
            buttonText="Save Plan"
            callback={(res) => {
              // save the plan to context, then redirect
              Event('selected_plan', {
                // Theres only one element in the array which is the $50 per month plan so just use that,
                // as soon as we have multiple plans we can swap back to the select box method.
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
              })
              context.update({
                // $
                // plan: res.data.plan
                plan: plans.data.list[0].value,
                subscription: res.data.subscription,
              })
              navigate(res.data.onboarded ? '/' : '/welcome')
              // navigate(res.data.onboarded ? '/dashboard' : '/welcome');
            }}
          />

          <div className="mt-4">
            <Link url="/account/profile" text="Manage Your Account" />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}
