import { useEffect, useState } from 'react'
import './checkbox.module.scss'
import { DownIcon } from 'icons'

export default function Checkbox({
  className,
  item: { value: valueProp, label: labelProp, checked: checkedProp, disabled },
  onChange: onChangeProp,
  isGroup,
  handleExpand,
  ...props
}) {
  const [checked, setChecked] = useState(checkedProp)

  useEffect(() => {
    setChecked(checkedProp)
  }, [checkedProp])

  const onChange = (e) => {
    setChecked(e.target.checked)
    if (onChangeProp) onChangeProp(e)
  }

  const handleExpandClick = (e) => {
    const expandEl = e.currentTarget
    const currentState = expandEl.getAttribute('data-expand') === 'true'
    expandEl.setAttribute('data-expand', (!currentState).toString())

    if (handleExpand) {
      handleExpand(!currentState)
    }
  }

  return (
    <div className="relative flex items-center space-x-2 w-full py-2">
      <input
        {...props}
        onChange={onChange}
        value={valueProp}
        id={labelProp}
        checked={checked}
        disabled={disabled}
        className={
          className +
          ' focus:border-pink  rounded border-gray-300 appearance-none  relative peer shrink-0 w-[18px] h-[18px] border-2 bg-[#CBD5E1] checked:bg-brand-500 checked:border-0 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:brand-400 disabled:border-[#C6C6C6] disabled:bg-[#C6C6C6]'
        }
        type="checkbox"
      />
      {Boolean(labelProp) && (
        <label htmlFor={labelProp} className="text-sm text-[#475569">
          {labelProp}
        </label>
      )}
      {isGroup && (
        <div
          onClick={handleExpandClick}
          data-expand="false"
          className="expandIcon absolute right-0 top-0 bottom-0 flex items-center justify-center w-6 h-full cursor-pointer"
        >
          <DownIcon className="transition-transform duration-300" />
        </div>
      )}
    </div>
  )
}
