const Style = {
  base: 'inline-block mx-1 py-1 px-2.5 text-white uppercase text-xs rounded',
  red: 'bg-red-500',
  blue: 'bg-blue-500',
  green: 'bg-emerald-500',
  violet: 'bg-violet-500',
  sky: 'bg-sky-500',
  brand2: 'bg-brand2-500',
  orange: 'bg-orange-500',
}

export default Style
