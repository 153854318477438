import dayjs from 'dayjs'
import { OS_TYPE } from './constants'

// Function to format the date
export const categorizeDate = (timeString) => {
  const date = dayjs(timeString)
  const today = dayjs().startOf('day')

  const diffInDays = today.diff(date.startOf('day'), 'day')

  if (diffInDays === 0) {
    return 'Today'
  } else if (diffInDays === 1) {
    return 'Yesterday'
  } else if (diffInDays <= 7) {
    return `Previous 7 Days`
  } else if (diffInDays <= 30) {
    return `Previous 30 Days`
  } else {
    return date.format('MMMM')
  }
}

export const formatDate = (date, format = 'DD-MM-YYYY') => {
  let localDateString = new Date(date)
  return dayjs(localDateString).format(format)
}
export function getSerializedDns(data) {
  const result = {}

  function recurse(obj, parentCategory = '') {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if ('namespace' in obj[key]) {
          let resultKey = key
          // LV This is a very dirty hack to get the worker lambdas to work
          // without needing to do a coupled release. Ideally the whole way
          // we send namespaces should be redone. This fix is good enough
          // until we (most likely me) change the way we send namespaces.
          if (key === 'Income Tax Act 2007') resultKey = 'Income Tax Act'

          result[resultKey] = {
            namespace: obj[key].namespace,
            checked: obj[key].checked,
          }
        } else {
          recurse(obj[key], key)
        }
      } else if (parentCategory && key === 'namespace') {
        result[parentCategory] = {
          namespace: obj[key],
          checked: obj.checked,
        }
      }
    }
  }

  recurse(data)
  return result
}

export const highlightText = (content, highlight) => {
  if (!highlight) return content
  const regex = new RegExp(`(${highlight})`, 'gi')
  return content.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="font-medium">
        {part}
      </span>
    ) : (
      part
    )
  )
}

export const truncateContent = (content, maxLength) => {
  return content.length > maxLength ? `${content.substring(0, maxLength)}...` : content
}

export function cn(...inputs) {
  const classes = inputs.flatMap((input) => {
    if (typeof input === 'string') {
      return input.split(' ')
    }
    if (Array.isArray(input)) {
      return input.flatMap((item) => cn(item))
    }
    if (typeof input === 'object' && input !== null) {
      return Object.entries(input)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => key)
    }
    return []
  })

  const uniqueClasses = new Set(classes)
  return Array.from(uniqueClasses).join(' ')
}

export const getOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  for (const [os, pattern] of Object.entries(OS_TYPE)) {
    if (pattern.test(userAgent)) {
      return os
    }
  }

  return 'unknown'
}
