import { Dashboard } from 'views/dashboard/dashboard'
import { Help } from 'views/dashboard/help'
import { LawCyborg } from 'views/dashboard/lawCyborg'
import { AiGeneral } from 'views/dashboard/aiGeneral'
import { DocUpload } from 'views/dashboard/docupload'
import { DepFinder } from 'views/dashboard/depfinder'
import { ProvTax } from 'views/dashboard/provtax'
import { Rnd } from 'views/dashboard/rnd'
import { OnboardingView } from 'views/onboarding/onboarding'
import { withWebSocket } from '../app/websocket'
import { GeneralAiIcon, TaxAiIcon } from 'icons'
import { Navigate } from 'react-router-dom'

const NavigateToTax = () => <Navigate to="/firstmate/tax" />
const NavigateToGeneral = () => <Navigate to="/firstmate/general" />
const options = [
  {
    label: 'General',
    icon: <GeneralAiIcon fill="currentColor" />,
    link: '/firstmate/general',
  },
  {
    label: 'Tax',
    icon: <TaxAiIcon fill="currentColor" />,
    link: '/firstmate/tax',
  },
]
const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  ...(process.env.NODE_ENV === 'production'
    ? [
        {
          path: '/',
          view: withWebSocket(LawCyborg),
          layout: 'app',
          permission: 'user',
          title: 'Tax',
          header: true,
        },
        {
          path: '/general-ai',
          view: withWebSocket(AiGeneral),
          layout: 'app',
          permission: 'user',
          title: 'General',
          header: true,
        },
        {
          path: '/tax-ai',
          view: withWebSocket(LawCyborg),
          layout: 'app',
          permission: 'user',
          title: 'Tax',
          header: true,
        },
      ]
    : [
        {
          path: '/',
          view: withWebSocket(LawCyborg),
          layout: 'app',
          permission: 'user',
          title: 'Firstmate',
          header: true,
          options,
        },
        {
          path: '/firstmate/general',
          view: withWebSocket(AiGeneral),
          layout: 'app',
          permission: 'user',
          title: 'Firstmate',
          header: true,
          options,
        },
        {
          path: '/firstmate/tax',
          view: withWebSocket(LawCyborg),
          layout: 'app',
          permission: 'user',
          title: 'Firstmate',
          header: true,
          options,
        },
        {
          path: '/tax-ai',
          view: NavigateToTax,
          layout: 'app',
          permission: 'user',
          title: 'Dashboard',
        },
        {
          path: '/general-ai',
          view: NavigateToGeneral,
          layout: 'app',
          permission: 'user',
          title: 'Dashboard',
        },
      ]),
  {
    path: '/r&d-ai',
    view: withWebSocket(LawCyborg),
    layout: 'app',
    permission: 'user',
    title: 'R&D AI',
  },
  {
    path: '/tp-ai',
    view: withWebSocket(LawCyborg),
    layout: 'app',
    permission: 'user',
    title: 'Transfer Pricing AI',
  },
  {
    path: '/dr-finder',
    view: withWebSocket(DepFinder),
    layout: 'app',
    permission: 'user',
    title: 'Depreciation Rate Finder',
    header: true,
  },
  {
    path: '/prov-calc',
    view: ProvTax,
    layout: 'app',
    permission: 'user',
    title: 'Provisional Tax Calculator',
    header: true,
  },
  {
    path: '/doc-question',
    view: withWebSocket(DocUpload),
    layout: 'app',
    permission: 'user',
    title: 'Document Questioner',
    header: true,
  },
  {
    path: '/cyborg/rnd',
    view: Rnd,
    layout: 'app',
    permission: 'user',
    title: 'Law Cyborg - RND',
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
]

export default Routes
