/***
 *
 *   APP LAYOUT
 *   Main application layout containing the navigation
 *   and header (title, secondary nav and signed in user)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import { AppNav, Header } from 'components/lib'
import { DocAiIcon, DrFinderIcon, FirstmateIcon, GeneralAiIcon, ProvTaxIcon, SearchIcon, TaxAiIcon } from 'icons'
import Style from './app.module.scss'
import { HINTS } from 'utils'

export function AppLayout(props) {
  const isGeneralAIView = props.title === 'General AI'
  const isDepView = props.title === 'Depreciation Rate Finder'
  let bgColorClass = ''
  if (isGeneralAIView) {
    bgColorClass = Style['app-bg-gray']
  } else if (isDepView) {
    bgColorClass = Style['app-bg-orange']
  }
  // hint: HINTS.generalAI
  // hint: HINTS.taxAI
  return (
    <div className="flex">
      <AppNav
        items={[
          ...(process.env.NODE_ENV === 'production'
            ? [
                { label: 'General AI', icon: <GeneralAiIcon />, link: '/general-ai', hint: HINTS.generalAI },
                { label: 'Tax AI Advanced', icon: <TaxAiIcon />, link: '/tax-ai', hint: HINTS.taxAI },
              ]
            : [{ label: 'Firstmate', icon: <FirstmateIcon />, link: '/firstmate/tax', hint: HINTS.firstmate }]),
          {
            label: 'Document Questioner',
            icon: <DocAiIcon />,
            link: '/doc-question',
            hint: HINTS.docQuestion,
          },
          {
            label: 'Depreciation Rate Finder',
            icon: <DrFinderIcon />,
            link: '/dr-finder',
            hint: HINTS.drFinder,
          },
          {
            label: 'Provisional Tax Calculator',
            icon: <ProvTaxIcon />,
            link: '/prov-calc',
            hint: HINTS.provCalc,
          },
          // TODO: remove this when we have a proper database search page
          process.env.NODE_ENV !== 'production' && {
            label: 'Seafarer',
            icon: <SearchIcon fill="#fff" height={15} width={15} />,
            link: '/seafarer',
          },
        ]}
        accountActionItems={[
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
        ]}
      />

      <main className={`${Style.app} ${bgColorClass}`}>
        {props.header && <Header title={props.title} options={props.options}></Header>}
        {<props.children {...props.data} />}
      </main>
    </div>
  )
}
