import { Button, ChatModeSelector, ChatNamespaceSelector } from 'components/lib'
import { useWindowSize } from 'hooks'
import { Settings } from 'icons'
import { useCallback, useEffect, useState } from 'react'
import { getOS } from 'utils/helper'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { HINTS } from 'utils'

const TaxAiChatSetting = ({ className = '', checkedNS, setCheckedNS, namespaceCheckItems, setMode, mode }) => {
  const os = getOS()
  const [settingOpen, setSettingOpen] = useState(false)
  const { isMobile } = useWindowSize()
  const buttonStyle = settingOpen
    ? 'bg-white rounded-full inline !h-8 !w-8 rounded-lg border [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
    : ' bg-white rounded-full inline h-10 w-10 shadow mt-1 drop-shadow border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
  const settingTitle = settingOpen ? 'Hide Chat Settings' : 'Chat Settings'
  const settingHint = os === 'macos' ? HINTS.chatSetting.mac : HINTS.chatSetting.nonMac
  const handleHistoryClose = () => {
    const historyButton = document.getElementById('history-toggle')
    if (historyButton.getAttribute('data-toggle') === 'true') historyButton.click()
  }

  const toggleSetting = useCallback(() => {
    if (!settingOpen && isMobile) handleHistoryClose()
    setSettingOpen((prev) => !prev)
  }, [settingOpen, isMobile])

  const handleKeyPress = useCallback(
    (event) => {
      const isModifierKey = os === 'macos' ? event.metaKey : event.ctrlKey

      if (isModifierKey && event.key === '.') {
        event.preventDefault()
        toggleSetting()
      }
    },
    [toggleSetting]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  return (
    <div
      style={settingOpen ? { boxShadow: '1px 0 2px 1px rgba(0, 0, 0, 0.1)' } : {}}
      className={`${className} inline z-10 overflow-y-auto left-0 m-0 absolute p-2 sm:pl-5  ${
        settingOpen
          ? 'bg-white lg:w-[13rem] xl:w-[15rem] 2xl:w-[19rem] w-[19rem] h-full top-0'
          : 'w-fit h-fit top-10 sm:top-0'
      } `}
    >
      <div className={!settingOpen ? '' : 'mb-2'}>
        <div className="flex justify-between items-center ">
          {settingOpen && (
            <div className="flex gap-8 mx-auto sm:mx-0">
              <ChatNamespaceSelector
                checkedNS={checkedNS}
                setCheckedNS={setCheckedNS}
                namespaceCheckItems={namespaceCheckItems}
              />

              <ChatModeSelector setMode={setMode} mode={mode} />
            </div>
          )}
          <Tippy content={`${settingTitle} (${settingHint})`} arrow={roundArrow} animation="shift-away" inertia={true}>
            <div>
              <Button
                id="chat-settings"
                data-toggle={settingOpen}
                icon={settingOpen && 'log-out'}
                customIcon={
                  settingOpen ? undefined : <Settings className={'w-fit h-fit m-auto'} height={22} width={22} />
                }
                color={'dark'}
                size={20}
                className={buttonStyle}
                action={(e) => {
                  if (!settingOpen && isMobile) handleHistoryClose()
                  setSettingOpen(!settingOpen)
                }}
              />
            </div>
          </Tippy>
          {/* <Button
            id="chat-settings"
            data-toggle={settingOpen}
            icon={settingOpen && 'log-out'}
            customIcon={settingOpen ? undefined : <Settings className={'w-fit h-fit m-auto'} height={22} width={22} />}
            color={'dark'}
            size={20}
            className={buttonStyle}
            action={(e) => {
              if (!settingOpen && isMobile) handleHistoryClose()
              setSettingOpen(!settingOpen)
            }}
          /> */}
        </div>
      </div>
    </div>
  )
}

export default TaxAiChatSetting
