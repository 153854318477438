import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import Select from 'react-select/creatable'
import { Icon } from 'components/lib'

const MAX_LENGTH_MESSAGE = 12000

export function ChatInputForm({
  inputMessage,
  setInputMessage,
  handleSubmit,
  enableSend,
  dropdownOptions,
  dropdownValue,
  setDropdownValue,
  placeholderText = 'Ask a question...',
  submitIcon = 'send',
  inputReadOnly,
  forwardedRef,
  onKeyDown,
  stopStreaming,
  autoFocus,
}) {
  const [isInputFocused, setInputFocused] = useState(false)
  const divClassName = () =>
    `bg-white w-full px-6 py-0 rounded-full flex drop-shadow-2xl border items-center${
      isInputFocused
        ? ' border-sky-500/45 ring-2 ring-sky-500/45'
        : ' hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
    }`

  const svgStrokeColor = inputMessage && enableSend ? '#474747' : 'grey'
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current && dropdownOptions?.length > 0) {
      setDropdownValue(dropdownOptions[0])
      isInitialMount.current = false
    }
  }, [dropdownOptions, setDropdownValue])

  const dropdownOptionsMap = useMemo(
    () => dropdownOptions?.map((option) => ({ value: option, label: option })),
    [dropdownOptions]
  )

  const handleInputChange = useCallback(
    (e) => {
      setInputMessage(e.target.value)

      // scuffed fix
      e.target.setAttribute('rows', 1)

      const numberOfLines = e.target.value.split('\n').length
      const wrappedLines = Math.floor(e.target.scrollHeight / 29) - 1
      let totalLines = numberOfLines + wrappedLines
      if (numberOfLines === 2 && wrappedLines === 1) {
        totalLines = 2
      }
      const newRows = Math.max(Math.min(totalLines, 4), 1)
      e.target.setAttribute('rows', newRows)
    },
    [setInputMessage]
  )

  useEffect(() => {
    if (!forwardedRef) return
    handleInputChange({ target: forwardedRef.current })
  }, [forwardedRef, handleInputChange])

  const handleKeyDown = (e) => {
    console.log(e)
    if (e.key === 'Enter' && !e.shiftKey && enableSend) {
      e.preventDefault()
      e.target.setAttribute('rows', 1)
      handleSubmit(e)
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    // Set textarea rows to 1 upon form submission
    e.target.querySelector('textarea').setAttribute('rows', '1')

    // Then call the provided handleSubmit function
    handleSubmit(e)
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className="self-center flex flex-col items-center md:w-[calc(100%-5rem)] w-[100%]"
    >
      <div id="inputDiv" className={divClassName()}>
        {dropdownOptions?.length > 0 && (
          <Select
            onBlur={(e) => {
              const inputValue = e.target.value
              if (inputValue && !dropdownOptions.includes(inputValue)) {
                setDropdownValue({ value: inputValue, label: inputValue })
              }
            }}
            menuPlacement="top"
            isClearable
            isSearchable
            value={dropdownValue}
            onChange={setDropdownValue}
            options={dropdownOptionsMap}
            formatCreateLabel={(option) => `${option}`}
            className="w-1/4 px-2 py-2 outline-none mr-2"
          />
        )}
        <textarea
          ref={forwardedRef}
          name="message"
          id="message"
          autoFocus={autoFocus}
          maxLength={MAX_LENGTH_MESSAGE}
          placeholder={placeholderText}
          value={inputMessage}
          onChange={(e) => handleInputChange(e)}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onKeyDown={(e) => {
            handleKeyDown(e)
            onKeyDown && onKeyDown(e)
          }}
          rows="1"
          disabled={inputReadOnly}
          className={`${
            dropdownOptions ? 'w-3/4' : 'w-full'
          } my-0 px-2 py-2 outline-none resize-none leading-normal max-h-24 overflow-y-auto box-border rounded-3xl`}
        />
        {enableSend ? (
          <button
            type="submit"
            className="self-end pl-0 pr-4 py-4 my-auto ml-auto rounded-full relative left-4"
            id="inputField"
            disabled={!inputMessage || !enableSend}
          >
            <Icon className="mx-auto" image={submitIcon} size={26} color={svgStrokeColor} />
          </button>
        ) : (
          <div className="self-end pl-0 pr-4 py-4 my-auto ml-auto rounded-full relative left-4" onClick={stopStreaming}>
            <Icon className="mx-auto cursor-pointer" image={'stop-circle'} size={26} color={'#474747'} />
          </div>
        )}
      </div>
    </form>
  )
}
