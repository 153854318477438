import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  ol.lst-kix_o1kgbd9vo5vp-7.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-7 0;
  }
  ol.lst-kix_x83m7w4x4n2i-3.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-3 0;
  }
  .lst-kix_64h5fm39ax1-4 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-4;
  }
  .lst-kix_55j22i8yl3um-4 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-4, lower-latin) '. ';
  }
  .lst-kix_55j22i8yl3um-6 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-6, decimal) '. ';
  }
  .lst-kix_28p8s5kcnl1h-2 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-2;
  }
  .lst-kix_55j22i8yl3um-5 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-5, lower-roman) '. ';
  }
  .lst-kix_kc94akeebeud-1 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-1, lower-latin) '. ';
  }
  .lst-kix_x83m7w4x4n2i-7 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-7;
  }
  .lst-kix_55j22i8yl3um-8 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-8, lower-roman) '. ';
  }
  .lst-kix_kc94akeebeud-2 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-2, lower-roman) '. ';
  }
  .lst-kix_kc94akeebeud-3 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-3, decimal) '. ';
  }
  .lst-kix_9z6x8148nyuv-3 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-3;
  }
  .lst-kix_55j22i8yl3um-7 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-7, lower-latin) '. ';
  }
  .lst-kix_q2tnc74j0p9o-2 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-2;
  }
  .lst-kix_kc94akeebeud-4 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-4, lower-latin) '. ';
  }
  .lst-kix_kc94akeebeud-5 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-5, lower-roman) '. ';
  }
  ol.lst-kix_8zbhwcau7ekb-4.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-4 0;
  }
  .lst-kix_55j22i8yl3um-1 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-1, lower-latin) '. ';
  }
  .lst-kix_55j22i8yl3um-6 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-6;
  }
  .lst-kix_k62d5rhz1by-4 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-4;
  }
  ol.lst-kix_bc8pkjyltm0d-0.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-0 0;
  }
  .lst-kix_kc94akeebeud-8 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-8, lower-roman) '. ';
  }
  .lst-kix_k62d5rhz1by-8 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-8, lower-roman) '. ';
  }
  .lst-kix_55j22i8yl3um-2 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-2, lower-roman) '. ';
  }
  .lst-kix_k62d5rhz1by-7 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-7, lower-latin) '. ';
  }
  .lst-kix_kc94akeebeud-6 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-6, decimal) '. ';
  }
  .lst-kix_kc94akeebeud-7 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-7, lower-latin) '. ';
  }
  .lst-kix_55j22i8yl3um-3 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-3, decimal) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-0.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-0 0;
  }
  .lst-kix_np06dzwb1oez-4 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-4;
  }
  ol.lst-kix_x83m7w4x4n2i-8.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-8 0;
  }
  .lst-kix_tsr250qa9qqg-1 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-1;
  }
  ol.lst-kix_tsr250qa9qqg-2.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-2 0;
  }
  .lst-kix_wjgyqmj3i527-5 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-5;
  }
  .lst-kix_55j22i8yl3um-0 > li:before {
    content: '' counter(lst-ctn-kix_55j22i8yl3um-0, decimal) '. ';
  }
  ol.lst-kix_k62d5rhz1by-6.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-6 0;
  }
  ol.lst-kix_q2tnc74j0p9o-4.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-4 0;
  }
  ol.lst-kix_kc94akeebeud-3.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-3 0;
  }
  ol.lst-kix_k62d5rhz1by-1.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-1 0;
  }
  ol.lst-kix_64h5fm39ax1-4 {
    list-style-type: none;
  }
  .lst-kix_k62d5rhz1by-1 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-1, lower-latin) '. ';
  }
  .lst-kix_9z6x8148nyuv-3 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-3, decimal) '. ';
  }
  ol.lst-kix_64h5fm39ax1-3 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-2 {
    list-style-type: none;
  }
  .lst-kix_k62d5rhz1by-0 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-0, decimal) '. ';
  }
  ol.lst-kix_64h5fm39ax1-1 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-8 {
    list-style-type: none;
  }
  .lst-kix_9z6x8148nyuv-1 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-1, lower-latin) '. ';
  }
  .lst-kix_9z6x8148nyuv-2 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-2, lower-roman) '. ';
  }
  ol.lst-kix_64h5fm39ax1-7 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-6 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-5 {
    list-style-type: none;
  }
  .lst-kix_9z6x8148nyuv-0 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-0, decimal) '. ';
  }
  .lst-kix_k62d5rhz1by-5 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-5, lower-roman) '. ';
  }
  .lst-kix_k62d5rhz1by-4 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-4, lower-latin) '. ';
  }
  .lst-kix_k62d5rhz1by-6 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-6, decimal) '. ';
  }
  .lst-kix_28p8s5kcnl1h-4 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-4, lower-latin) '. ';
  }
  ol.lst-kix_64h5fm39ax1-0 {
    list-style-type: none;
  }
  .lst-kix_28p8s5kcnl1h-5 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-5, lower-roman) '. ';
  }
  .lst-kix_bc8pkjyltm0d-3 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-3;
  }
  ol.lst-kix_6srwkyy95nt9-1.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-1 0;
  }
  .lst-kix_55j22i8yl3um-2 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-2;
  }
  .lst-kix_kc94akeebeud-7 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-7;
  }
  .lst-kix_tlekd9x6mar7-8 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-8;
  }
  .lst-kix_k62d5rhz1by-2 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-2, lower-roman) '. ';
  }
  .lst-kix_28p8s5kcnl1h-6 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-6, decimal) '. ';
  }
  .lst-kix_28p8s5kcnl1h-8 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-8, lower-roman) '. ';
  }
  .lst-kix_k62d5rhz1by-3 > li:before {
    content: '' counter(lst-ctn-kix_k62d5rhz1by-3, decimal) '. ';
  }
  .lst-kix_fx5s7g7vtfrm-4 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-4, lower-latin) '. ';
  }
  .lst-kix_28p8s5kcnl1h-7 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-7, lower-latin) '. ';
  }
  ol.lst-kix_bc8pkjyltm0d-5.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-5 0;
  }
  .lst-kix_fx5s7g7vtfrm-5 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-5, lower-roman) '. ';
  }
  .lst-kix_fx5s7g7vtfrm-6 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-6, decimal) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-7.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-7 0;
  }
  .lst-kix_fx5s7g7vtfrm-7 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-7, lower-latin) '. ';
  }
  .lst-kix_np06dzwb1oez-0 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-0;
  }
  ol.lst-kix_6srwkyy95nt9-6.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-6 0;
  }
  .lst-kix_64h5fm39ax1-8 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-8;
  }
  .lst-kix_fx5s7g7vtfrm-8 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-8, lower-roman) '. ';
  }
  ol.lst-kix_tlekd9x6mar7-4.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-4 0;
  }
  ol.lst-kix_wjgyqmj3i527-6.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-6 0;
  }
  .lst-kix_tsr250qa9qqg-5 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-5;
  }
  ol.lst-kix_np06dzwb1oez-3.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-3 0;
  }
  .lst-kix_6srwkyy95nt9-5 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-5;
  }
  .lst-kix_64h5fm39ax1-1 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-1, lower-latin) '. ';
  }
  .lst-kix_k62d5rhz1by-0 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-0;
  }
  .lst-kix_64h5fm39ax1-5 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-5, lower-roman) '. ';
  }
  ol.lst-kix_o1kgbd9vo5vp-0.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-0 0;
  }
  ol.lst-kix_28p8s5kcnl1h-1 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-0 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-3.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-3 0;
  }
  ol.lst-kix_np06dzwb1oez-8 {
    list-style-type: none;
  }
  .lst-kix_bc8pkjyltm0d-6 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-6;
  }
  ol.lst-kix_np06dzwb1oez-7 {
    list-style-type: none;
  }
  ol.lst-kix_np06dzwb1oez-6 {
    list-style-type: none;
  }
  ol.lst-kix_np06dzwb1oez-5 {
    list-style-type: none;
  }
  ol.lst-kix_np06dzwb1oez-4 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-4.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-4 0;
  }
  ol.lst-kix_np06dzwb1oez-3 {
    list-style-type: none;
  }
  ol.lst-kix_np06dzwb1oez-2 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-8 {
    list-style-type: none;
  }
  ol.lst-kix_np06dzwb1oez-1 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-7 {
    list-style-type: none;
  }
  .lst-kix_64h5fm39ax1-3 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-3, decimal) '. ';
  }
  ol.lst-kix_np06dzwb1oez-0 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-6 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-5 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-4 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-3 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-2 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-4.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-4 0;
  }
  ol.lst-kix_np06dzwb1oez-8.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-8 0;
  }
  .lst-kix_8zbhwcau7ekb-6 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-6;
  }
  .lst-kix_fx5s7g7vtfrm-3 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-3, decimal) '. ';
  }
  ol.lst-kix_tlekd9x6mar7-6.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-6 0;
  }
  .lst-kix_fx5s7g7vtfrm-1 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-1, lower-latin) '. ';
  }
  .lst-kix_fx5s7g7vtfrm-6 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-6;
  }
  .lst-kix_28p8s5kcnl1h-3 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-3, decimal) '. ';
  }
  ol.lst-kix_np06dzwb1oez-5.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-5 0;
  }
  .lst-kix_np06dzwb1oez-1 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-1;
  }
  .lst-kix_64h5fm39ax1-7 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-7, lower-latin) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-3 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-3;
  }
  .lst-kix_28p8s5kcnl1h-6 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-6;
  }
  .lst-kix_28p8s5kcnl1h-1 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-1, lower-latin) '. ';
  }
  .lst-kix_9z6x8148nyuv-4 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-4, lower-latin) '. ';
  }
  .lst-kix_3etqoxr10xo-1 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-1;
  }
  .lst-kix_wjgyqmj3i527-8 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-8;
  }
  .lst-kix_9z6x8148nyuv-6 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-6, decimal) '. ';
  }
  .lst-kix_9z6x8148nyuv-7 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-7;
  }
  .lst-kix_9z6x8148nyuv-8 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-8, lower-roman) '. ';
  }
  .lst-kix_hxydwnux7hhd-0 > li:before {
    content: '-  ';
  }
  .lst-kix_hxydwnux7hhd-2 > li:before {
    content: '-  ';
  }
  ol.lst-kix_28p8s5kcnl1h-1.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-1 0;
  }
  .lst-kix_6srwkyy95nt9-8 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-8, lower-roman) '. ';
  }
  ol.lst-kix_64h5fm39ax1-2.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-2 0;
  }
  .lst-kix_np06dzwb1oez-8 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-8;
  }
  .lst-kix_hxydwnux7hhd-4 > li:before {
    content: '-  ';
  }
  ol.lst-kix_6srwkyy95nt9-7 {
    list-style-type: none;
  }
  .lst-kix_o1kgbd9vo5vp-0 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-0, decimal) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-6.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-6 0;
  }
  ol.lst-kix_6srwkyy95nt9-8 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-5 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-6 {
    list-style-type: none;
  }
  .lst-kix_9z6x8148nyuv-6 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-6;
  }
  ol.lst-kix_6srwkyy95nt9-3 {
    list-style-type: none;
  }
  .lst-kix_tlekd9x6mar7-5 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-5, lower-roman) '. ';
  }
  .lst-kix_6srwkyy95nt9-0 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-0, decimal) '. ';
  }
  ol.lst-kix_6srwkyy95nt9-4 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-1 {
    list-style-type: none;
  }
  ol.lst-kix_bc8pkjyltm0d-3.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-3 0;
  }
  ol.lst-kix_6srwkyy95nt9-2 {
    list-style-type: none;
  }
  ol.lst-kix_x83m7w4x4n2i-1.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-1 0;
  }
  .lst-kix_tlekd9x6mar7-3 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-3, decimal) '. ';
  }
  ol.lst-kix_6srwkyy95nt9-0 {
    list-style-type: none;
  }
  .lst-kix_9z6x8148nyuv-0 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-0;
  }
  ol.lst-kix_o1kgbd9vo5vp-2.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-2 0;
  }
  .lst-kix_3etqoxr10xo-2 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-2;
  }
  .lst-kix_kc94akeebeud-0 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-0;
  }
  .lst-kix_tlekd9x6mar7-1 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-1;
  }
  .lst-kix_x83m7w4x4n2i-0 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-0;
  }
  .lst-kix_tlekd9x6mar7-1 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-1, lower-latin) '. ';
  }
  .lst-kix_wjgyqmj3i527-1 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-1;
  }
  .lst-kix_bc8pkjyltm0d-7 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-7;
  }
  ol.lst-kix_x83m7w4x4n2i-4 {
    list-style-type: none;
  }
  .lst-kix_kc94akeebeud-0 > li:before {
    content: '' counter(lst-ctn-kix_kc94akeebeud-0, decimal) '. ';
  }
  ol.lst-kix_x83m7w4x4n2i-5 {
    list-style-type: none;
  }
  ol.lst-kix_x83m7w4x4n2i-6 {
    list-style-type: none;
  }
  .lst-kix_x83m7w4x4n2i-1 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-1, lower-latin) '. ';
  }
  ol.lst-kix_x83m7w4x4n2i-7 {
    list-style-type: none;
  }
  ol.lst-kix_x83m7w4x4n2i-0 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-2.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-2 0;
  }
  ol.lst-kix_x83m7w4x4n2i-1 {
    list-style-type: none;
  }
  ol.lst-kix_x83m7w4x4n2i-2 {
    list-style-type: none;
  }
  .lst-kix_o1kgbd9vo5vp-2 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-2;
  }
  ol.lst-kix_x83m7w4x4n2i-3 {
    list-style-type: none;
  }
  .lst-kix_x83m7w4x4n2i-3 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-3, decimal) '. ';
  }
  .lst-kix_6srwkyy95nt9-2 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-2, lower-roman) '. ';
  }
  .lst-kix_hxydwnux7hhd-8 > li:before {
    content: '-  ';
  }
  .lst-kix_k62d5rhz1by-5 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-5;
  }
  ol.lst-kix_x83m7w4x4n2i-8 {
    list-style-type: none;
  }
  .lst-kix_8zbhwcau7ekb-5 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-5;
  }
  .lst-kix_6srwkyy95nt9-4 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-4, lower-latin) '. ';
  }
  ol.lst-kix_x83m7w4x4n2i-0.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-0 0;
  }
  .lst-kix_x83m7w4x4n2i-7 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-7, lower-latin) '. ';
  }
  .lst-kix_6srwkyy95nt9-6 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-6, decimal) '. ';
  }
  .lst-kix_hxydwnux7hhd-6 > li:before {
    content: '-  ';
  }
  ol.lst-kix_kc94akeebeud-0.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-0 0;
  }
  .lst-kix_x83m7w4x4n2i-5 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-5, lower-roman) '. ';
  }
  ol.lst-kix_bc8pkjyltm0d-2.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-2 0;
  }
  .lst-kix_fx5s7g7vtfrm-5 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-5;
  }
  ol.lst-kix_tsr250qa9qqg-7.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-7 0;
  }
  .lst-kix_9z6x8148nyuv-4 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-4;
  }
  ol.lst-kix_6srwkyy95nt9-3.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-3 0;
  }
  .lst-kix_55j22i8yl3um-5 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-5;
  }
  .lst-kix_wjgyqmj3i527-4 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-4, lower-roman) '. ';
  }
  ol.lst-kix_o1kgbd9vo5vp-2 {
    list-style-type: none;
  }
  ol.lst-kix_kc94akeebeud-1.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-1 0;
  }
  ol.lst-kix_o1kgbd9vo5vp-3 {
    list-style-type: none;
  }
  .lst-kix_wjgyqmj3i527-3 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-3, lower-latin) '. ';
  }
  ol.lst-kix_o1kgbd9vo5vp-4 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-8.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-8 0;
  }
  ol.lst-kix_o1kgbd9vo5vp-5 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-2.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-2 0;
  }
  ol.lst-kix_o1kgbd9vo5vp-6 {
    list-style-type: none;
  }
  ol.lst-kix_o1kgbd9vo5vp-7 {
    list-style-type: none;
  }
  ol.lst-kix_o1kgbd9vo5vp-8 {
    list-style-type: none;
  }
  .lst-kix_wjgyqmj3i527-0 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-0, lower-latin) '. ';
  }
  .lst-kix_np06dzwb1oez-5 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-5;
  }
  ol.lst-kix_tlekd9x6mar7-8.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-8 0;
  }
  ol.lst-kix_8zbhwcau7ekb-1.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-1 0;
  }
  ol.lst-kix_o1kgbd9vo5vp-0 {
    list-style-type: none;
  }
  ol.lst-kix_o1kgbd9vo5vp-1 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-2.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-2 0;
  }
  ol.lst-kix_k62d5rhz1by-8 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-7 {
    list-style-type: none;
  }
  .lst-kix_55j22i8yl3um-3 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-3;
  }
  ol.lst-kix_8zbhwcau7ekb-6.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-6 0;
  }
  .lst-kix_o1kgbd9vo5vp-7 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-7, lower-latin) '. ';
  }
  .lst-kix_q2tnc74j0p9o-1 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-1, lower-latin) '. ';
  }
  .lst-kix_q2tnc74j0p9o-5 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-5, lower-roman) '. ';
  }
  ol.lst-kix_28p8s5kcnl1h-5.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-5 0;
  }
  ol.lst-kix_55j22i8yl3um-7.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-7 0;
  }
  ol.lst-kix_kc94akeebeud-8 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-7.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-7 0;
  }
  .lst-kix_6srwkyy95nt9-6 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-6;
  }
  ol.lst-kix_tsr250qa9qqg-5.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-5 0;
  }
  .lst-kix_q2tnc74j0p9o-0 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-0, decimal) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-2 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-2, lower-roman) '. ';
  }
  .lst-kix_tsr250qa9qqg-4 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-4;
  }
  ol.lst-kix_np06dzwb1oez-1.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-1 0;
  }
  ol.lst-kix_kc94akeebeud-2 {
    list-style-type: none;
  }
  .lst-kix_tlekd9x6mar7-7 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-7, lower-latin) '. ';
  }
  ol.lst-kix_kc94akeebeud-3 {
    list-style-type: none;
  }
  ol.lst-kix_kc94akeebeud-0 {
    list-style-type: none;
  }
  .lst-kix_wjgyqmj3i527-2 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-2;
  }
  ol.lst-kix_kc94akeebeud-1 {
    list-style-type: none;
  }
  ol.lst-kix_kc94akeebeud-6 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-8.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-8 0;
  }
  .lst-kix_o1kgbd9vo5vp-3 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-3, decimal) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-3.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-3 0;
  }
  ol.lst-kix_kc94akeebeud-7 {
    list-style-type: none;
  }
  ol.lst-kix_kc94akeebeud-4 {
    list-style-type: none;
  }
  .lst-kix_tlekd9x6mar7-8 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-8, lower-roman) '. ';
  }
  ol.lst-kix_kc94akeebeud-5 {
    list-style-type: none;
  }
  .lst-kix_tlekd9x6mar7-0 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-0;
  }
  .lst-kix_wjgyqmj3i527-8 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-8, decimal) '. ';
  }
  ol.lst-kix_k62d5rhz1by-0 {
    list-style-type: none;
  }
  .lst-kix_wjgyqmj3i527-7 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-7, lower-roman) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-8.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-8 0;
  }
  ol.lst-kix_k62d5rhz1by-2 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-1 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-4 {
    list-style-type: none;
  }
  .lst-kix_np06dzwb1oez-7 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-7;
  }
  .lst-kix_q2tnc74j0p9o-4 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-4, lower-latin) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-6 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-6, decimal) '. ';
  }
  ol.lst-kix_k62d5rhz1by-3 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-6 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-5 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-8.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-8 0;
  }
  ol.lst-kix_np06dzwb1oez-2.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-2 0;
  }
  ol.lst-kix_q2tnc74j0p9o-6 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-7 {
    list-style-type: none;
  }
  .lst-kix_tsr250qa9qqg-1 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-1, lower-latin) '. ';
  }
  ol.lst-kix_q2tnc74j0p9o-4 {
    list-style-type: none;
  }
  .lst-kix_k62d5rhz1by-3 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-3;
  }
  ol.lst-kix_q2tnc74j0p9o-5 {
    list-style-type: none;
  }
  .lst-kix_tsr250qa9qqg-2 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-2, lower-roman) '. ';
  }
  ol.lst-kix_q2tnc74j0p9o-8 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-8.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-8 0;
  }
  ol.lst-kix_tsr250qa9qqg-4.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-4 0;
  }
  ol.lst-kix_8zbhwcau7ekb-7.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-7 0;
  }
  .lst-kix_bc8pkjyltm0d-2 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-2;
  }
  ol.lst-kix_55j22i8yl3um-8.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-8 0;
  }
  ol.lst-kix_28p8s5kcnl1h-4.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-4 0;
  }
  .lst-kix_kc94akeebeud-8 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-8;
  }
  ol.lst-kix_q2tnc74j0p9o-2 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-3 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-0 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-1 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-7.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-7 0;
  }
  .lst-kix_tsr250qa9qqg-6 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-6, decimal) '. ';
  }
  .lst-kix_np06dzwb1oez-8 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-8, lower-roman) '. ';
  }
  .lst-kix_8zbhwcau7ekb-1 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-1;
  }
  .lst-kix_kc94akeebeud-1 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-1;
  }
  ol.lst-kix_55j22i8yl3um-1.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-1 0;
  }
  .lst-kix_wjgyqmj3i527-6 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-6;
  }
  .lst-kix_tsr250qa9qqg-5 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-5, lower-roman) '. ';
  }
  .lst-kix_x83m7w4x4n2i-4 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-4;
  }
  ol.lst-kix_3etqoxr10xo-1.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-1 0;
  }
  .lst-kix_np06dzwb1oez-0 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-0, decimal) '. ';
  }
  .lst-kix_np06dzwb1oez-4 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-4, lower-latin) '. ';
  }
  .lst-kix_np06dzwb1oez-1 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-1, lower-latin) '. ';
  }
  .lst-kix_np06dzwb1oez-5 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-5, lower-roman) '. ';
  }
  .lst-kix_64h5fm39ax1-5 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-5;
  }
  ol.lst-kix_3etqoxr10xo-7.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-7 0;
  }
  .lst-kix_np06dzwb1oez-3 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-3;
  }
  ol.lst-kix_8zbhwcau7ekb-2.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-2 0;
  }
  ul.lst-kix_hxydwnux7hhd-6 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-8 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-5 {
    list-style-type: none;
  }
  ol.lst-kix_q2tnc74j0p9o-6.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-6 0;
  }
  ol.lst-kix_8zbhwcau7ekb-7 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-4 {
    list-style-type: none;
  }
  .lst-kix_q2tnc74j0p9o-1 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-1;
  }
  ol.lst-kix_8zbhwcau7ekb-6 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-3 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-5 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-2.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-2 0;
  }
  ul.lst-kix_hxydwnux7hhd-2 {
    list-style-type: none;
  }
  .lst-kix_9z6x8148nyuv-2 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-2;
  }
  ol.lst-kix_64h5fm39ax1-0.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-0 0;
  }
  ol.lst-kix_8zbhwcau7ekb-4 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-1 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-3 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-0 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-2 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-1 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-0 {
    list-style-type: none;
  }
  .lst-kix_6srwkyy95nt9-8 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-8;
  }
  .lst-kix_tsr250qa9qqg-8 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-8;
  }
  ul.lst-kix_hxydwnux7hhd-8 {
    list-style-type: none;
  }
  ul.lst-kix_hxydwnux7hhd-7 {
    list-style-type: none;
  }
  .lst-kix_64h5fm39ax1-0 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-0, decimal) '. ';
  }
  ol.lst-kix_8zbhwcau7ekb-5.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-5 0;
  }
  ol.lst-kix_55j22i8yl3um-6.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-6 0;
  }
  .lst-kix_o1kgbd9vo5vp-4 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-4;
  }
  ol.lst-kix_kc94akeebeud-5.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-5 0;
  }
  .lst-kix_64h5fm39ax1-4 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-4, lower-latin) '. ';
  }
  .lst-kix_tlekd9x6mar7-5 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-5;
  }
  .lst-kix_6srwkyy95nt9-1 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-1;
  }
  .lst-kix_bc8pkjyltm0d-2 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-2, lower-roman) '. ';
  }
  ol.lst-kix_3etqoxr10xo-3.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-3 0;
  }
  ol.lst-kix_tsr250qa9qqg-3.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-3 0;
  }
  ol.lst-kix_9z6x8148nyuv-2.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-2 0;
  }
  .lst-kix_3etqoxr10xo-6 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-6, decimal) '. ';
  }
  ol.lst-kix_3etqoxr10xo-6.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-6 0;
  }
  ol.lst-kix_q2tnc74j0p9o-8.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-8 0;
  }
  ol.lst-kix_28p8s5kcnl1h-6.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-6 0;
  }
  .lst-kix_bc8pkjyltm0d-6 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-6, decimal) '. ';
  }
  .lst-kix_8zbhwcau7ekb-3 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-3;
  }
  ol.lst-kix_tsr250qa9qqg-7 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-0 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-8 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-4.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-4 0;
  }
  ol.lst-kix_fx5s7g7vtfrm-1 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-2 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-3 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-3 {
    list-style-type: none;
  }
  .lst-kix_fx5s7g7vtfrm-2 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-2, lower-roman) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-4 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-4 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-5 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-5 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-6 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-6 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-7 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-8 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-0 {
    list-style-type: none;
  }
  ol.lst-kix_tsr250qa9qqg-1 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-3.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-3 0;
  }
  ol.lst-kix_tsr250qa9qqg-2 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-2.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-2 0;
  }
  .lst-kix_q2tnc74j0p9o-6 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-6;
  }
  .lst-kix_64h5fm39ax1-8 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-8, lower-roman) '. ';
  }
  .lst-kix_x83m7w4x4n2i-2 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-2;
  }
  .lst-kix_tlekd9x6mar7-3 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-3;
  }
  .lst-kix_28p8s5kcnl1h-0 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-0, decimal) '. ';
  }
  .lst-kix_k62d5rhz1by-1 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-1;
  }
  ol.lst-kix_kc94akeebeud-8.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-8 0;
  }
  ol.lst-kix_wjgyqmj3i527-1.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-1 0;
  }
  .lst-kix_o1kgbd9vo5vp-6 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-6;
  }
  .lst-kix_tsr250qa9qqg-6 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-6;
  }
  .lst-kix_9z6x8148nyuv-7 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-7, lower-latin) '. ';
  }
  .lst-kix_6srwkyy95nt9-7 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-7, lower-latin) '. ';
  }
  .lst-kix_x83m7w4x4n2i-8 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-8, lower-roman) '. ';
  }
  .lst-kix_8zbhwcau7ekb-3 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-3, decimal) '. ';
  }
  ol.lst-kix_28p8s5kcnl1h-8.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-8 0;
  }
  .lst-kix_64h5fm39ax1-0 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-0;
  }
  ol.lst-kix_tsr250qa9qqg-1.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-1 0;
  }
  .lst-kix_28p8s5kcnl1h-4 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-4;
  }
  ol.lst-kix_wjgyqmj3i527-3.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-3 0;
  }
  .lst-kix_hxydwnux7hhd-3 > li:before {
    content: '-  ';
  }
  .lst-kix_8zbhwcau7ekb-7 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-7, lower-latin) '. ';
  }
  ol.lst-kix_kc94akeebeud-7.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-7 0;
  }
  ol.lst-kix_9z6x8148nyuv-4.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-4 0;
  }
  .lst-kix_q2tnc74j0p9o-8 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-8, lower-roman) '. ';
  }
  ol.lst-kix_3etqoxr10xo-4.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-4 0;
  }
  ol.lst-kix_55j22i8yl3um-4.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-4 0;
  }
  .lst-kix_x83m7w4x4n2i-0 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-0, decimal) '. ';
  }
  .lst-kix_tlekd9x6mar7-4 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-4, lower-latin) '. ';
  }
  .lst-kix_64h5fm39ax1-7 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-7;
  }
  .lst-kix_3etqoxr10xo-5 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-5;
  }
  .lst-kix_kc94akeebeud-3 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-3;
  }
  .lst-kix_tlekd9x6mar7-0 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-0, decimal) '. ';
  }
  ol.lst-kix_6srwkyy95nt9-4.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-4 0;
  }
  .lst-kix_wjgyqmj3i527-4 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-4;
  }
  ol.lst-kix_tsr250qa9qqg-0.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-0 0;
  }
  .lst-kix_bc8pkjyltm0d-4 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-4;
  }
  ol.lst-kix_28p8s5kcnl1h-7.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-7 0;
  }
  ol.lst-kix_9z6x8148nyuv-5.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-5 0;
  }
  ol.lst-kix_kc94akeebeud-6.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-6 0;
  }
  .lst-kix_k62d5rhz1by-8 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-8;
  }
  .lst-kix_3etqoxr10xo-2 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-2, lower-roman) '. ';
  }
  .lst-kix_fx5s7g7vtfrm-2 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-2;
  }
  .lst-kix_8zbhwcau7ekb-8 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-8;
  }
  .lst-kix_6srwkyy95nt9-3 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-3, decimal) '. ';
  }
  ol.lst-kix_np06dzwb1oez-0.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-0 0;
  }
  .lst-kix_x83m7w4x4n2i-4 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-4, lower-latin) '. ';
  }
  ol.lst-kix_55j22i8yl3um-5.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-5 0;
  }
  .lst-kix_hxydwnux7hhd-7 > li:before {
    content: '-  ';
  }
  ol.lst-kix_9z6x8148nyuv-6.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-6 0;
  }
  .lst-kix_tsr250qa9qqg-3 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-3;
  }
  .lst-kix_6srwkyy95nt9-7 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-7;
  }
  ol.lst-kix_tlekd9x6mar7-5.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-5 0;
  }
  ol.lst-kix_np06dzwb1oez-4.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-4 0;
  }
  .lst-kix_wjgyqmj3i527-3 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-3;
  }
  ol.lst-kix_wjgyqmj3i527-5.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-5 0;
  }
  .lst-kix_k62d5rhz1by-6 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-6;
  }
  ol.lst-kix_kc94akeebeud-4.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-4 0;
  }
  ol.lst-kix_64h5fm39ax1-8.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-8 0;
  }
  .lst-kix_fx5s7g7vtfrm-0 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-0;
  }
  .lst-kix_q2tnc74j0p9o-0 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-0;
  }
  .lst-kix_8zbhwcau7ekb-0 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-0;
  }
  ol.lst-kix_o1kgbd9vo5vp-1.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-1 0;
  }
  ol.lst-kix_k62d5rhz1by-0.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-0 0;
  }
  ol.lst-kix_3etqoxr10xo-5.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-5 0;
  }
  .lst-kix_x83m7w4x4n2i-5 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-5;
  }
  ol.lst-kix_fx5s7g7vtfrm-5.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-5 0;
  }
  ol.lst-kix_28p8s5kcnl1h-2.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-2 0;
  }
  .lst-kix_3etqoxr10xo-7 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-7;
  }
  .lst-kix_28p8s5kcnl1h-0 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-0;
  }
  ol.lst-kix_bc8pkjyltm0d-7 {
    list-style-type: none;
  }
  ol.lst-kix_6srwkyy95nt9-5.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-5 0;
  }
  ol.lst-kix_bc8pkjyltm0d-8 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-1.start {
    counter-reset: lst-ctn-kix_9z6x8148nyuv-1 0;
  }
  .lst-kix_fx5s7g7vtfrm-7 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-7;
  }
  ol.lst-kix_bc8pkjyltm0d-3 {
    list-style-type: none;
  }
  ol.lst-kix_bc8pkjyltm0d-4 {
    list-style-type: none;
  }
  ol.lst-kix_bc8pkjyltm0d-5 {
    list-style-type: none;
  }
  ol.lst-kix_bc8pkjyltm0d-6 {
    list-style-type: none;
  }
  .lst-kix_bc8pkjyltm0d-8 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-8;
  }
  ol.lst-kix_bc8pkjyltm0d-0 {
    list-style-type: none;
  }
  .lst-kix_3etqoxr10xo-0 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-0;
  }
  ol.lst-kix_bc8pkjyltm0d-1 {
    list-style-type: none;
  }
  ol.lst-kix_8zbhwcau7ekb-3.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-3 0;
  }
  .lst-kix_55j22i8yl3um-8 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-8;
  }
  ol.lst-kix_bc8pkjyltm0d-2 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-0.start {
    counter-reset: lst-ctn-kix_wjgyqmj3i527-0 0;
  }
  ol.lst-kix_bc8pkjyltm0d-6.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-6 0;
  }
  .lst-kix_o1kgbd9vo5vp-1 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-1;
  }
  .lst-kix_8zbhwcau7ekb-7 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-7;
  }
  .lst-kix_np06dzwb1oez-6 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-6;
  }
  ol.lst-kix_8zbhwcau7ekb-8.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-8 0;
  }
  .lst-kix_9z6x8148nyuv-5 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-5;
  }
  ol.lst-kix_3etqoxr10xo-0.start {
    counter-reset: lst-ctn-kix_3etqoxr10xo-0 0;
  }
  .lst-kix_q2tnc74j0p9o-4 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-4;
  }
  .lst-kix_64h5fm39ax1-2 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-2;
  }
  ol.lst-kix_o1kgbd9vo5vp-6.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-6 0;
  }
  ol.lst-kix_6srwkyy95nt9-0.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-0 0;
  }
  .lst-kix_bc8pkjyltm0d-5 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-5, lower-roman) '. ';
  }
  .lst-kix_28p8s5kcnl1h-7 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-7;
  }
  ol.lst-kix_tsr250qa9qqg-6.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-6 0;
  }
  .lst-kix_bc8pkjyltm0d-1 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-1, lower-latin) '. ';
  }
  .lst-kix_fx5s7g7vtfrm-4 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-4;
  }
  .lst-kix_bc8pkjyltm0d-3 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-3, decimal) '. ';
  }
  ol.lst-kix_bc8pkjyltm0d-4.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-4 0;
  }
  .lst-kix_3etqoxr10xo-3 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-3;
  }
  ol.lst-kix_6srwkyy95nt9-7.start {
    counter-reset: lst-ctn-kix_6srwkyy95nt9-7 0;
  }
  ol.lst-kix_q2tnc74j0p9o-5.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-5 0;
  }
  .lst-kix_8zbhwcau7ekb-4 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-4;
  }
  ol.lst-kix_bc8pkjyltm0d-1.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-1 0;
  }
  .lst-kix_x83m7w4x4n2i-1 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-1;
  }
  .lst-kix_q2tnc74j0p9o-8 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-8;
  }
  ol.lst-kix_3etqoxr10xo-1 {
    list-style-type: none;
  }
  ol.lst-kix_64h5fm39ax1-1.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-1 0;
  }
  ol.lst-kix_3etqoxr10xo-2 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-0 {
    list-style-type: none;
  }
  .lst-kix_bc8pkjyltm0d-7 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-7, lower-latin) '. ';
  }
  .lst-kix_28p8s5kcnl1h-8 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-8;
  }
  .lst-kix_3etqoxr10xo-7 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-7, lower-latin) '. ';
  }
  ol.lst-kix_55j22i8yl3um-0.start {
    counter-reset: lst-ctn-kix_55j22i8yl3um-0 0;
  }
  .lst-kix_6srwkyy95nt9-3 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-3;
  }
  .lst-kix_55j22i8yl3um-0 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-0;
  }
  ol.lst-kix_kc94akeebeud-2.start {
    counter-reset: lst-ctn-kix_kc94akeebeud-2 0;
  }
  ol.lst-kix_x83m7w4x4n2i-2.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-2 0;
  }
  .lst-kix_kc94akeebeud-5 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-5;
  }
  .lst-kix_tlekd9x6mar7-6 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-6;
  }
  .lst-kix_8zbhwcau7ekb-0 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-0, decimal) '. ';
  }
  .lst-kix_6srwkyy95nt9-4 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-4;
  }
  ol.lst-kix_8zbhwcau7ekb-0.start {
    counter-reset: lst-ctn-kix_8zbhwcau7ekb-0 0;
  }
  .lst-kix_9z6x8148nyuv-1 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-1;
  }
  ol.lst-kix_q2tnc74j0p9o-3.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-3 0;
  }
  .lst-kix_8zbhwcau7ekb-4 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-4, lower-latin) '. ';
  }
  .lst-kix_8zbhwcau7ekb-6 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-6, decimal) '. ';
  }
  .lst-kix_8zbhwcau7ekb-8 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-8, lower-roman) '. ';
  }
  ol.lst-kix_wjgyqmj3i527-1 {
    list-style-type: none;
  }
  ol.lst-kix_fx5s7g7vtfrm-7.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-7 0;
  }
  ol.lst-kix_tsr250qa9qqg-8.start {
    counter-reset: lst-ctn-kix_tsr250qa9qqg-8 0;
  }
  ol.lst-kix_wjgyqmj3i527-0 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-3 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-2 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-5 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-4 {
    list-style-type: none;
  }
  .lst-kix_np06dzwb1oez-2 > li {
    counter-increment: lst-ctn-kix_np06dzwb1oez-2;
  }
  ol.lst-kix_wjgyqmj3i527-7 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-6 {
    list-style-type: none;
  }
  ol.lst-kix_wjgyqmj3i527-8 {
    list-style-type: none;
  }
  .lst-kix_wjgyqmj3i527-7 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-7;
  }
  .lst-kix_bc8pkjyltm0d-1 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-1;
  }
  .lst-kix_kc94akeebeud-6 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-6;
  }
  .lst-kix_tlekd9x6mar7-7 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-7;
  }
  .lst-kix_x83m7w4x4n2i-6 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-6;
  }
  ol.lst-kix_3etqoxr10xo-7 {
    list-style-type: none;
  }
  ol.lst-kix_28p8s5kcnl1h-0.start {
    counter-reset: lst-ctn-kix_28p8s5kcnl1h-0 0;
  }
  ol.lst-kix_3etqoxr10xo-8 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-5 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-6 {
    list-style-type: none;
  }
  .lst-kix_o1kgbd9vo5vp-8 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-8;
  }
  ol.lst-kix_3etqoxr10xo-3 {
    list-style-type: none;
  }
  ol.lst-kix_3etqoxr10xo-4 {
    list-style-type: none;
  }
  .lst-kix_3etqoxr10xo-5 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-5, lower-roman) '. ';
  }
  ol.lst-kix_np06dzwb1oez-6.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-6 0;
  }
  ol.lst-kix_64h5fm39ax1-3.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-3 0;
  }
  .lst-kix_3etqoxr10xo-3 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-3, decimal) '. ';
  }
  .lst-kix_3etqoxr10xo-8 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-8;
  }
  .lst-kix_3etqoxr10xo-1 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-1, lower-latin) '. ';
  }
  .lst-kix_55j22i8yl3um-1 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-1;
  }
  ol.lst-kix_o1kgbd9vo5vp-3.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-3 0;
  }
  .lst-kix_8zbhwcau7ekb-2 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-2, lower-roman) '. ';
  }
  .lst-kix_55j22i8yl3um-7 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-7;
  }
  ol.lst-kix_tlekd9x6mar7-7.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-7 0;
  }
  ol.lst-kix_tlekd9x6mar7-4 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-3 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-2 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-1 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-0 {
    list-style-type: none;
  }
  ol.lst-kix_k62d5rhz1by-3.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-3 0;
  }
  ol.lst-kix_q2tnc74j0p9o-1.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-1 0;
  }
  .lst-kix_bc8pkjyltm0d-0 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-0;
  }
  .lst-kix_28p8s5kcnl1h-1 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-1;
  }
  ol.lst-kix_tlekd9x6mar7-8 {
    list-style-type: none;
  }
  .lst-kix_64h5fm39ax1-3 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-3;
  }
  .lst-kix_x83m7w4x4n2i-8 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-8;
  }
  ol.lst-kix_tlekd9x6mar7-7 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-6 {
    list-style-type: none;
  }
  ol.lst-kix_tlekd9x6mar7-5 {
    list-style-type: none;
  }
  ol.lst-kix_o1kgbd9vo5vp-4.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-4 0;
  }
  ol.lst-kix_bc8pkjyltm0d-8.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-8 0;
  }
  .lst-kix_wjgyqmj3i527-2 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-2, decimal) '. ';
  }
  .lst-kix_wjgyqmj3i527-1 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-1, lower-roman) '. ';
  }
  ol.lst-kix_np06dzwb1oez-7.start {
    counter-reset: lst-ctn-kix_np06dzwb1oez-7 0;
  }
  .lst-kix_q2tnc74j0p9o-2 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-2, lower-roman) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-8 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-8, lower-roman) '. ';
  }
  .lst-kix_q2tnc74j0p9o-3 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-3;
  }
  .lst-kix_q2tnc74j0p9o-6 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-6, decimal) '. ';
  }
  .lst-kix_k62d5rhz1by-7 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-7;
  }
  .lst-kix_tlekd9x6mar7-6 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-6, decimal) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-2.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-2 0;
  }
  .lst-kix_q2tnc74j0p9o-7 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-7, lower-latin) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-0 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-0;
  }
  .lst-kix_wjgyqmj3i527-5 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-5, decimal) '. ';
  }
  .lst-kix_wjgyqmj3i527-6 > li:before {
    content: '' counter(lst-ctn-kix_wjgyqmj3i527-6, lower-latin) '. ';
  }
  .lst-kix_28p8s5kcnl1h-3 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-3;
  }
  ol.lst-kix_tlekd9x6mar7-2.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-2 0;
  }
  .lst-kix_o1kgbd9vo5vp-4 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-4, lower-latin) '. ';
  }
  .lst-kix_o1kgbd9vo5vp-5 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-5, lower-roman) '. ';
  }
  ol.lst-kix_64h5fm39ax1-5.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-5 0;
  }
  .lst-kix_q2tnc74j0p9o-3 > li:before {
    content: '' counter(lst-ctn-kix_q2tnc74j0p9o-3, decimal) '. ';
  }
  ol.lst-kix_k62d5rhz1by-8.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-8 0;
  }
  .lst-kix_tsr250qa9qqg-0 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-0, decimal) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-3.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-3 0;
  }
  .lst-kix_fx5s7g7vtfrm-1 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-1;
  }
  ol.lst-kix_o1kgbd9vo5vp-5.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-5 0;
  }
  .lst-kix_o1kgbd9vo5vp-7 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-7;
  }
  .lst-kix_tlekd9x6mar7-2 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-2;
  }
  .lst-kix_tsr250qa9qqg-7 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-7, lower-latin) '. ';
  }
  ol.lst-kix_q2tnc74j0p9o-0.start {
    counter-reset: lst-ctn-kix_q2tnc74j0p9o-0 0;
  }
  ol.lst-kix_64h5fm39ax1-6.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-6 0;
  }
  .lst-kix_3etqoxr10xo-6 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-6;
  }
  .lst-kix_tsr250qa9qqg-4 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-4, lower-latin) '. ';
  }
  .lst-kix_tsr250qa9qqg-3 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-3, decimal) '. ';
  }
  .lst-kix_wjgyqmj3i527-0 > li {
    counter-increment: lst-ctn-kix_wjgyqmj3i527-0;
  }
  .lst-kix_28p8s5kcnl1h-5 > li {
    counter-increment: lst-ctn-kix_28p8s5kcnl1h-5;
  }
  ol.lst-kix_tlekd9x6mar7-3.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-3 0;
  }
  ol.lst-kix_k62d5rhz1by-2.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-2 0;
  }
  .lst-kix_np06dzwb1oez-2 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-2, lower-roman) '. ';
  }
  .lst-kix_np06dzwb1oez-6 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-6, decimal) '. ';
  }
  .lst-kix_np06dzwb1oez-7 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-7, lower-latin) '. ';
  }
  .lst-kix_tsr250qa9qqg-8 > li:before {
    content: '' counter(lst-ctn-kix_tsr250qa9qqg-8, lower-roman) '. ';
  }
  .lst-kix_6srwkyy95nt9-2 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-2;
  }
  .lst-kix_tsr250qa9qqg-2 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-2;
  }
  .lst-kix_9z6x8148nyuv-8 > li {
    counter-increment: lst-ctn-kix_9z6x8148nyuv-8;
  }
  .lst-kix_q2tnc74j0p9o-7 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-7;
  }
  .lst-kix_np06dzwb1oez-3 > li:before {
    content: '' counter(lst-ctn-kix_np06dzwb1oez-3, decimal) '. ';
  }
  .lst-kix_bc8pkjyltm0d-4 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-4, lower-latin) '. ';
  }
  .lst-kix_bc8pkjyltm0d-8 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-8, lower-roman) '. ';
  }
  ol.lst-kix_64h5fm39ax1-7.start {
    counter-reset: lst-ctn-kix_64h5fm39ax1-7 0;
  }
  .lst-kix_64h5fm39ax1-6 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-6, decimal) '. ';
  }
  .lst-kix_bc8pkjyltm0d-5 > li {
    counter-increment: lst-ctn-kix_bc8pkjyltm0d-5;
  }
  ol.lst-kix_55j22i8yl3um-8 {
    list-style-type: none;
  }
  ol.lst-kix_x83m7w4x4n2i-7.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-7 0;
  }
  ol.lst-kix_fx5s7g7vtfrm-1.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-1 0;
  }
  ol.lst-kix_k62d5rhz1by-7.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-7 0;
  }
  .lst-kix_6srwkyy95nt9-0 > li {
    counter-increment: lst-ctn-kix_6srwkyy95nt9-0;
  }
  .lst-kix_64h5fm39ax1-2 > li:before {
    content: '' counter(lst-ctn-kix_64h5fm39ax1-2, lower-roman) '. ';
  }
  .lst-kix_kc94akeebeud-4 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-4;
  }
  .lst-kix_3etqoxr10xo-8 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-8, lower-roman) '. ';
  }
  ol.lst-kix_x83m7w4x4n2i-4.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-4 0;
  }
  .lst-kix_fx5s7g7vtfrm-3 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-3;
  }
  ol.lst-kix_55j22i8yl3um-1 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-0 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-3 {
    list-style-type: none;
  }
  ol.lst-kix_o1kgbd9vo5vp-8.start {
    counter-reset: lst-ctn-kix_o1kgbd9vo5vp-8 0;
  }
  .lst-kix_tsr250qa9qqg-7 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-7;
  }
  ol.lst-kix_55j22i8yl3um-2 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-5 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-4 {
    list-style-type: none;
  }
  .lst-kix_fx5s7g7vtfrm-0 > li:before {
    content: '' counter(lst-ctn-kix_fx5s7g7vtfrm-0, decimal) '. ';
  }
  ol.lst-kix_55j22i8yl3um-7 {
    list-style-type: none;
  }
  ol.lst-kix_55j22i8yl3um-6 {
    list-style-type: none;
  }
  .lst-kix_28p8s5kcnl1h-2 > li:before {
    content: '' counter(lst-ctn-kix_28p8s5kcnl1h-2, lower-roman) '. ';
  }
  ol.lst-kix_bc8pkjyltm0d-7.start {
    counter-reset: lst-ctn-kix_bc8pkjyltm0d-7 0;
  }
  .lst-kix_kc94akeebeud-2 > li {
    counter-increment: lst-ctn-kix_kc94akeebeud-2;
  }
  .lst-kix_9z6x8148nyuv-5 > li:before {
    content: '' counter(lst-ctn-kix_9z6x8148nyuv-5, lower-roman) '. ';
  }
  .lst-kix_3etqoxr10xo-4 > li {
    counter-increment: lst-ctn-kix_3etqoxr10xo-4;
  }
  ol.lst-kix_x83m7w4x4n2i-5.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-5 0;
  }
  ol.lst-kix_tlekd9x6mar7-0.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-0 0;
  }
  .lst-kix_64h5fm39ax1-6 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-6;
  }
  .lst-kix_hxydwnux7hhd-1 > li:before {
    content: '-  ';
  }
  .lst-kix_8zbhwcau7ekb-5 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-5, lower-roman) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-2 {
    list-style-type: none;
  }
  .lst-kix_64h5fm39ax1-1 > li {
    counter-increment: lst-ctn-kix_64h5fm39ax1-1;
  }
  ol.lst-kix_k62d5rhz1by-5.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-5 0;
  }
  .lst-kix_o1kgbd9vo5vp-1 > li:before {
    content: '' counter(lst-ctn-kix_o1kgbd9vo5vp-1, lower-latin) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-1 {
    list-style-type: none;
  }
  .lst-kix_6srwkyy95nt9-1 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-1, lower-latin) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-0 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-8 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-7 {
    list-style-type: none;
  }
  .lst-kix_q2tnc74j0p9o-5 > li {
    counter-increment: lst-ctn-kix_q2tnc74j0p9o-5;
  }
  ol.lst-kix_9z6x8148nyuv-6 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-5 {
    list-style-type: none;
  }
  .lst-kix_tlekd9x6mar7-2 > li:before {
    content: '' counter(lst-ctn-kix_tlekd9x6mar7-2, lower-roman) '. ';
  }
  ol.lst-kix_9z6x8148nyuv-4 {
    list-style-type: none;
  }
  ol.lst-kix_9z6x8148nyuv-3 {
    list-style-type: none;
  }
  .lst-kix_tsr250qa9qqg-0 > li {
    counter-increment: lst-ctn-kix_tsr250qa9qqg-0;
  }
  .lst-kix_tlekd9x6mar7-4 > li {
    counter-increment: lst-ctn-kix_tlekd9x6mar7-4;
  }
  .lst-kix_x83m7w4x4n2i-3 > li {
    counter-increment: lst-ctn-kix_x83m7w4x4n2i-3;
  }
  .lst-kix_o1kgbd9vo5vp-5 > li {
    counter-increment: lst-ctn-kix_o1kgbd9vo5vp-5;
  }
  ol.lst-kix_tlekd9x6mar7-1.start {
    counter-reset: lst-ctn-kix_tlekd9x6mar7-1 0;
  }
  ol.lst-kix_x83m7w4x4n2i-6.start {
    counter-reset: lst-ctn-kix_x83m7w4x4n2i-6 0;
  }
  .lst-kix_3etqoxr10xo-4 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-4, lower-latin) '. ';
  }
  ol.lst-kix_k62d5rhz1by-4.start {
    counter-reset: lst-ctn-kix_k62d5rhz1by-4 0;
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_k62d5rhz1by-2 > li {
    counter-increment: lst-ctn-kix_k62d5rhz1by-2;
  }
  .lst-kix_x83m7w4x4n2i-2 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-2, lower-roman) '. ';
  }
  ol.lst-kix_fx5s7g7vtfrm-0.start {
    counter-reset: lst-ctn-kix_fx5s7g7vtfrm-0 0;
  }
  .lst-kix_fx5s7g7vtfrm-8 > li {
    counter-increment: lst-ctn-kix_fx5s7g7vtfrm-8;
  }
  .lst-kix_bc8pkjyltm0d-0 > li:before {
    content: '' counter(lst-ctn-kix_bc8pkjyltm0d-0, decimal) '. ';
  }
  .lst-kix_hxydwnux7hhd-5 > li:before {
    content: '-  ';
  }
  .lst-kix_8zbhwcau7ekb-2 > li {
    counter-increment: lst-ctn-kix_8zbhwcau7ekb-2;
  }
  .lst-kix_6srwkyy95nt9-5 > li:before {
    content: '' counter(lst-ctn-kix_6srwkyy95nt9-5, lower-roman) '. ';
  }
  .lst-kix_8zbhwcau7ekb-1 > li:before {
    content: '' counter(lst-ctn-kix_8zbhwcau7ekb-1, lower-latin) '. ';
  }
  .lst-kix_55j22i8yl3um-4 > li {
    counter-increment: lst-ctn-kix_55j22i8yl3um-4;
  }
  .lst-kix_3etqoxr10xo-0 > li:before {
    content: '' counter(lst-ctn-kix_3etqoxr10xo-0, decimal) '. ';
  }
  .lst-kix_x83m7w4x4n2i-6 > li:before {
    content: '' counter(lst-ctn-kix_x83m7w4x4n2i-6, decimal) '. ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c6 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c0 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c9 {
    padding-top: 18pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c17 {
    padding-top: 0pt;
    padding-bottom: 3pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt;
  }
  .c11 {
    color: #123680;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c15 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c16 {
    color: #123680;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 26pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c18 {
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c5 {
    padding: 0;
    margin: 0;
  }
  .c12 {
    margin-left: 108pt;
    padding-left: 0pt;
  }
  .c14 {
    margin-left: 72pt;
    padding-left: 0pt;
  }
  .c4 {
    background-color: #ffffff;
  }
  .c13 {
    background-color: #ffff00;
  }
  .c10 {
    font-weight: 700;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
`

const LCLSubscriberTermsConditionsAgreement = () => {
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <StyledWrapper className="mb-10">
      <div>
        <head>
          <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
        </head>
        <body class="c4 doc-content" className="md:p-10 p-2">
          <p class="c17 title mb-10" id="h.8nrzkqst67fh">
            <span class="c10 c16">Subscriber Terms &amp; Conditions Agreement</span>
          </p>
          <p class="c7">
            <span>
              This Subscriber Terms and Conditions Agreement (hereinafter referred to as &ldquo;Agreement&rdquo;) is
              entered on{' '}
            </span>
            <span class="c10">{today}</span>
            <span class="c3">
              &nbsp;between Law Cyborg Limited (hereinafter referred to as &ldquo;LCL&rdquo;) and the person(s) you
              represent (hereinafter referred to as &ldquo;Subscriber&rdquo;).
            </span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <p class="c7">
            <span class="c3">
              LCL and the Subscriber are jointly referred to as &ldquo;Parties&rdquo; and individually as
              &ldquo;Party&rdquo;.
            </span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <p class="c7">
            <span class="c10">Background:</span>
          </p>
          <p class="c7">
            <span class="c3">
              The Subscriber wishes to obtain access to the Law Cyborg Platform generally accessible via the domain name
              app.lawcyborg.com (hereinafter referred to as &ldquo;Platform&rdquo;). The Platform provides a variety of
              tools for use by professionals. LCL agrees to provide access to the Platform as well as ancillary
              technical support (hereinafter refer to as the &ldquo;Services&rdquo;) as per the terms of this Agreement.
            </span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.j4j6bhcb31ft">
            <span class="c11 c10">1. Minimum term &amp; renewal</span>
          </h2>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_k62d5rhz1by-0 start" start="1">
            <li class="c0 li-bullet-0">
              <span class="c3">There is no minimum term for the Services.</span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c3">
                Subscriber agrees to have their Services automatically renewed upon the close of each subscription
                month. This includes renewal under new terms which the Subscriber has been notified of at least 10
                working days in advance of renewal.
              </span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c3">The Subscriber may cancel the renewal of the Services at any time by either:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_k62d5rhz1by-1 start" start="1">
            <li class="c7 c14 li-bullet-0">
              <span class="c3">Deleting their account; or</span>
            </li>
            <li class="c7 c14 li-bullet-0">
              <span>Giving LCL notice in the form of an email to </span>
              <span>support@lawcyborg.com</span>
              <span class="c3">&nbsp;and ceasing to use the Services entirely.</span>
            </li>
          </ol>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.wvi0imk0m6n8">
            <span class="c11 c10">2. Fees</span>
          </h2>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_x83m7w4x4n2i-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c10">Base Fee:</span>
              <span class="c3">
                &nbsp;The Base Fee for use of the Platform is $39.00 NZD plus GST per user per month.{' '}
              </span>
            </li>
          </ol>
          <ol class="c5 lst-kix_x83m7w4x4n2i-1 start" start="1">
            <li class="c15 c14 li-bullet-0">
              <span class="c3">Payment of this fee provides:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_x83m7w4x4n2i-2 start" start="1">
            <li class="c15 c12 li-bullet-0">
              <span class="c3">access to the Platform for one month; and</span>
            </li>
            <li class="c12 c15 li-bullet-0">
              <span class="c3">unlimited tokens; and</span>
            </li>
            <li class="c12 c15 li-bullet-0">
              <span class="c3">technical support.</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_x83m7w4x4n2i-0" start="2">
            <li class="c6 li-bullet-0">
              <span class="c10">Abuse fee:</span>
              <span class="c3">
                &nbsp;Tokens used via botting or other abuse (in breach of clause 2.1 of LCL’s end-user terms of use)
                may be charged to the subscriber at a rate of $0.09 NZD plus GST per 1,000 tokens.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Tokens used:</span>
              <span>
                &nbsp;Any tokens sent to or returned by our service provider&rsquo;s generative pre-trained transformer
                API via the user, system or otherwise, are counted.
              </span>
            </li>
          </ol>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.cp8qnwmb96i9">
            <span class="c11 c10">3. Payment terms</span>
          </h2>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_kc94akeebeud-0 start" start="1">
            <li class="c0 li-bullet-0">
              <span class="c3">
                The Subscriber shall pay LCL the fees described under &ldquo;Fees&rdquo; of this Agreement.
              </span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c3">The Base Fee must be paid before access to the Platform is granted each month.</span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c3">
                Any balance outstanding after 30 days of the invoice mailing date may be subject to a finance charge.
              </span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c3">
                LCL further reserves the right to suspend Services until outstanding balances are paid.
              </span>
            </li>
            <li class="c0 li-bullet-0">
              <span>
                Payment may be transacted through Stripe (&ldquo;Facilitated Payment Mode&rdquo;, or &ldquo;FPM&rdquo;).
                If this is the case, the Subscriber agrees to the terms of use governing the FPM service.
              </span>
            </li>
          </ol>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.xbe4x6pc9kpx">
            <span class="c11 c10">4. Platform Access</span>
          </h2>
          <p class="c2">
            <span class="c1"></span>
          </p>
          <ol class="c5 lst-kix_tlekd9x6mar7-0 start" start="1">
            <li class="c0 li-bullet-0">
              <span class="c10">Authorised Users:</span>
              <span class="c3">
                &nbsp;A Subscriber may allow employees and/or independent contractors (hereinafter
                &ldquo;Employees&rdquo;) to use the Platform on behalf of the Subscriber as authorised users.
              </span>
            </li>
            <li class="c0 li-bullet-0">
              <span class="c10">User conditions:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_tlekd9x6mar7-1 start" start="1">
            <li class="c7 c14 li-bullet-0">
              <span class="c3">Conditional to access and use the Platform:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_tlekd9x6mar7-2 start" start="1">
            <li class="c7 c12 li-bullet-0">
              <span class="c3">
                each user shall agree to abide by the terms of LCL&rsquo;s end-user terms of use which it may adopt from
                time to time; and
              </span>
            </li>
            <li class="c7 c12 li-bullet-0">
              <span class="c3">subscriptions cannot be shared or used by more than one Employee.</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_tlekd9x6mar7-0" start="3">
            <li class="c0 li-bullet-0">
              <span class="c1">Account responsibility:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_tlekd9x6mar7-1 start" start="1">
            <li class="c7 c14 li-bullet-0">
              <span class="c3">
                The Subscriber shall be responsible for all usage of any account that the Subscriber is connected to,
                whether or not the Subscriber has authorised the particular use or user, and regardless of the
                Subscriber&rsquo;s knowledge of such use.
              </span>
            </li>
            <li class="c7 c14 li-bullet-0">
              <span class="c3">
                LCL shall not be responsible for any loss of information or use due to any irresponsible act such as
                loss of the password by a user.
              </span>
            </li>
          </ol>
          <ol class="c5 lst-kix_tlekd9x6mar7-0" start="4">
            <li class="c0 li-bullet-0">
              <span class="c10">Availability: </span>
              <span class="c3">
                LCL will use reasonable efforts to ensure the Service is available on a 24 hour basis. However, it is
                possible that on occasion the Service may be unavailable to permit maintenance or other development
                activity, or in the event of Force Majeure. LCL will use reasonable efforts to notify the Subscriber
                advance details of any unavailability.{' '}
              </span>
            </li>
          </ol>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.m13sx4hs3mte">
            <span class="c11 c10">5. Confidentiality</span>
          </h2>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <p class="c15">
            <span class="c3">
              All confidential information that is communicated to and obtained by LCL from the Subscriber in connection
              with performing the above-mentioned services shall be held by LCL in full trust. At no time LCL shall use
              any confidential information obtained through conducting this service contract either directly or
              indirectly, for personal benefit, or disclose or communicate such information in any manner, unless
              required by law.{' '}
            </span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.gr72x1wta2ss">
            <span class="c10 c11">6. Reliance on information</span>
          </h2>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <p class="c7">
            <span>
              LCL is an early-stage startup company. The accuracy of the information contained or output by the Platform
              is not guaranteed. The Subscriber is not permitted to solely rely on the accuracy of Services provided,
              including any information provided by the Platform&rsquo;s tools. LCL is not responsible for any loss
              caused by the reliance on information contained or output by the Platform.
            </span>
          </p>
          <p class="c2">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.ih9e4vku2s9l">
            <span class="c11 c10">7. Proprietary Rights</span>
          </h2>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_64h5fm39ax1-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c10">Non-identifiable Subscriber Data:</span>
              <span class="c3">
                &nbsp;The Subscriber hereby grants LCL a non-exclusive, transferable, sublicensable, worldwide, and
                royalty-free licence to use and otherwise exploit (i) subscriber data to provide the Services to the
                Subscriber hereunder and as necessary or useful to monitor and improve the Services, both during and
                after the subscription period. To avoid any doubt, the LCL shall use, reproduce and disclose
                Services-related information, data, and material that is anonymized, de-identified, or otherwise
                rendered not reasonably associated or linked to Subscriber, all of which information, data, and
                material, will be owned by LCL.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Identifiable Subscriber Data:</span>
              <span class="c3">
                &nbsp;For marketing purposes, LCL may disclose that the Subscriber is party to an agreement with LCL.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Intellectual Property</span>
              <span class="c3">
                : LCL shall retain all the subscription services, including all documentation, modifications,
                improvements, upgrades, derivative works, and all other Intellectual Property rights in connection with
                the Service, including the LCL&#39;s name, logos and trademarks reproduced through the Service.
              </span>
            </li>
          </ol>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.xt5nt0d0j7cq">
            <span class="c11 c10">8. Security</span>
          </h2>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_np06dzwb1oez-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c10">Compliance with Notification Laws:</span>
              <span class="c3">
                &nbsp;LCL shall comply with all applicable laws regarding the notification of individuals in the event
                of an unauthorised release of personally identifiable information and notification of other unauthorised
                data and information disclosures.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c4 c10">Procedure After Unauthorised Disclosure:</span>
              <span class="c4">&nbsp;Within 10 working days</span>
              <span class="c4 c10">&nbsp;</span>
              <span class="c4">
                of discovering any breach of LCL&rsquo;s security obligations or any other event requiring notification
                under applicable law, LCL shall notify the Subscriber, and any other individual&#39;s Law requires to be
                notified, of the breach or other events by telephone and e-mail.
              </span>
            </li>
          </ol>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.49di1s8lsdv7">
            <span class="c11 c10">9. Limitation of Liability</span>
          </h2>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_55j22i8yl3um-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c3">In no event shall either Party:</span>
            </li>
          </ol>
          <ol class="c5 lst-kix_55j22i8yl3um-1 start" start="1">
            <li class="c15 c14 li-bullet-0">
              <span class="c3">
                Have liability arising out of or related to this Agreement, whether, in contract, tort or under any
                other theory of liability exceed in the aggregate of the total fees paid or owed by the Subscriber
                hereunder during the month immediately preceding the date of the event giving rise to the claim (such
                amount being intended as a cumulative cap and not per incident).{' '}
              </span>
            </li>
            <li class="c15 c14 li-bullet-0">
              <span class="c3">
                Have any liability to the other for any lost profits or revenues or any indirect, incidental,
                consequential, cover, special, exemplary, or punitive damages, however caused, whether, in contract,
                tort, or under any other conditions of liability.
              </span>
            </li>
          </ol>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <h2 class="c9" id="h.1rhyb5uvnfug">
            <span class="c11 c10">10. Miscellaneous</span>
          </h2>
          <p class="c8">
            <span class="c3"></span>
          </p>
          <ol class="c5 lst-kix_q2tnc74j0p9o-0 start" start="1">
            <li class="c6 li-bullet-0">
              <span class="c10">Modification of end-user terms:</span>
              <span>
                &nbsp;It is agreed and accepted that LCL may make reasonable updates to the Platform&rsquo;s end-user
                terms from time to time.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Consent to contact:</span>
              <span class="c3">
                &nbsp;LCL is granted permission to contact the users connected to the Subscriber's account for marketing
                purposes or otherwise.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Ability to change LLMs:</span>
              <span class="c3">
                The Subscriber agrees that LCL has the ability under this contract to change large language models
                (LLMs) utilised in their systems at LCL's sole discretion and understands that such change will affect
                the accuracy and quality of information output by the tools.{' '}
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Assignability:</span>
              <span class="c3">
                &nbsp;Neither party may assign this Agreement or the rights and obligations thereunder to any third
                party without the prior express written approval of the other Party which shall not be unreasonably
                withheld.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Non-exclusive:</span>
              <span class="c3">
                &nbsp;LCL&rsquo;s provision of the Services to the Subscriber is non-exclusive. Nothing in the Agreement
                prevents LCL from providing the Services to any other person.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Rights of third parties:</span>
              <span class="c3">
                &nbsp;No person other than the Subscriber and LCL has any right to benefit under, or to enforce, the
                Agreement. &nbsp;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Force Majeure:</span>
              <span class="c3">
                &nbsp;Neither party shall be liable for any failure in performance of the obligation under this
                Agreement due to cause beyond that party&#39;s reasonable control (including and not limited to any
                pandemic, fire, strike, act or order of public authority, and other acts of God) during the pendency of
                such event.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Modification:</span>
              <span class="c3">
                &nbsp;No modification of this Agreement shall be made unless in writing, signed by both parties.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Severability:</span>
              <span class="c3">
                &nbsp;If any term, clause, or provision hereof is held invalid or unenforceable by a court of competent
                jurisdiction, all other terms will remain in full force and effect until the Agreement termination.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Governing Law and Jurisdiction:</span>
              <span class="c3">
                &nbsp;This Agreement shall be governed by, and must be interpreted in accordance with, the laws of New
                Zealand.{' '}
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c10">Legal and Binding Agreement:</span>
              <span>
                &nbsp;This Agreement is legal and binding between the Parties as stated above. The Parties each
                represent that they have the authority to enter into this Agreement.
              </span>
            </li>
          </ol>
        </body>
      </div>
    </StyledWrapper>
  )
}

export default LCLSubscriberTermsConditionsAgreement
