/***
 *
 *   BILLING / PLAN
 *   Update the billing plan
 *
 **********/

import React, { useContext } from 'react'
import { AuthContext, Card, Form, Message, usePlans } from 'components/lib'

export function BillingPlan(props) {
  const plans = usePlans()
  const context = useContext(AuthContext)

  return (
    <Card loading={plans.loading || props.subscription.loading} restrictWidth className={props.className}>
      {props.subscription?.data?.object && (
        <Message
          type="info"
          title="Your Billing Cycle"
          text={`${props.subscription.data.object.current_period_start} to ${props.subscription.data.object.current_period_end}`}
        />
      )}
      <a href="https://lawcyborg.com/subscriber-terms.pdf" target="_blank">
        <div class="mb-4 cursor-pointer font-sans w-[49%] inline-block mr-[2%] last:mr-0 !w-full bg-gradient-to-r from-gray-500 to-gray-500 hover:bg-gradient-to-r hover:from-gray-600 hover:to-gray-600 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500 text-white">
          Terms
        </div>
      </a>
      <Form
        inputs={{
          plan: {
            label: 'Your subscription plan',
            type: 'select',
            required: true,
            default: plans?.data?.active,
            options: plans?.data?.list,
          },
        }}
        url="/api/account/plan"
        method="PATCH"
        buttonText="Update Plan"
        callback={(res) => {
          context.update({ plan: res.data.data.plan })
        }}
      />
    </Card>
  )
}
