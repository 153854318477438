import React, { useEffect, useState } from 'react'
import { Icon } from 'components/lib'

export function ChatDisclaimer(props) {
  const { setInputMessage, handleSubmit } = props
  const [clickMessage, setClickMessage] = useState(null)

  const ICON_SIZE = 30
  const ICON_COLOUR = 'black/80'

  const handleClick = (message) => {
    setClickMessage(message)
    setInputMessage(message)
  }

  useEffect(() => {
    if (clickMessage) {
      handleSubmit({ preventDefault: () => {} })
    }
  }, [clickMessage, handleSubmit])

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 select-none lg:w-[60%] md:w-[80%] mx-auto mt-4">
      {props.disclaimers.map((col, idx) => (
        <div key={idx} className={`md:col-span-1 ${idx === 0 ? 'hidden lg:block' : ''}`}>
          <div className="rounded p-6 text-center mb-4 text-xl">
            <Icon className="mx-auto my-4" image={col.icon.image} size={ICON_SIZE} color={ICON_COLOUR} />
            {col.title}
          </div>
          {col.content.map((text, idx) => (
            <div
              key={idx}
              onClick={col.isClickable ? () => handleClick(text) : null}
              className={`rounded bg-white p-6 text-center flex items-center justify-center mb-4 ${col.isClickable ? 'hover:bg-gray-200 hover:text-black cursor-pointer' : ''}`}
            >
              {text}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
