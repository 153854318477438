import React, { useState, useRef } from 'react'
import {
  ChatInputForm,
  ConversationDisplay,
  RefreshButton,
  useConversation,
  ChatDisclaimer,
  useConversationAutoScroll,
} from 'components/lib'

import { general } from './definitions'

import ChatHistory from 'components/ai/chatHistory'

export function AiGeneral() {
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)

  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    branch,
    swapBranch,
    loadConversation,
    stopStreaming,
    loadConversations,
    historyCached,
    conversationId,
  } = useConversation(general)
  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>

  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative">
      <ChatHistory
        enableRefresh={exchanges.length}
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f5f5f5'}
        onOpen={loadConversations}
        loading={!historyCached}
      />
      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%]  no-scrollbar" id="chat">
        {!exchanges.length && (
          <ChatDisclaimer
            setInputMessage={setInputMessage}
            handleSubmit={handleSubmit}
            disclaimers={general.disclaimers}
          />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
        />
      </div>

      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        {/* <RefreshButton enabled={exchanges.length} /> */}
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
        />
      </div>
    </div>
  )
}
